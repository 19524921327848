import React from 'react';
import get from 'lodash.get';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import '../sharedComponents/scss/SCreboot.module.scss';


const __DEV__ = process.env.NODE_ENV === 'development';

const Layout = ({ children, pageSeo = {}, path }) => (
    <StaticQuery
        query={graphql`
            {
                brandCenterVersion {
                    slug
                    active
                    updatedAt
                }
                brandCenterSite {
                    s3PublicUrl
                }
                brandCenterTemplate {
					values {
						title
						favicon {
							kind
							originValue
						}
						metaDescription
						metaOgTitle
						metaOgImage {
							kind
							gatsbyImage {
								publicURL
							}
							originValue
						}
						metaOgDescription
						metaTwitterTitle
						metaTwitterImage {
							value
							kind
							gatsbyImage {
								publicURL
							}
						}
						metaTwitterDescription
						metaThemeColor
					}
				}
            }
        `}
        render={({
            brandCenterVersion,
            brandCenterTemplate,
            brandCenterSite
        }) => {
            const {
                slug,
                active,
                updatedAt
            } = brandCenterVersion;

            const {
                s3PublicUrl
            } = brandCenterSite;

            const {
                title,
                metaOgTitle,
                metaTwitterTitle,
                metaDescription,
                metaOgDescription,
                metaTwitterDescription,
                metaOgImage,
                metaTwitterImage,
                metaThemeColor,
                favicon
            } = brandCenterTemplate.values;

            const metaOgImageUrl = get(metaOgImage, 'gatsbyImage.publicURL');
            const metaTwitterImageUrl = get(metaTwitterImage, 'gatsbyImage.publicURL');
            const faviconVersion = favicon && favicon.originValue ? favicon.originValue : '0';

            const getAssetUrl = name => {
                if (__DEV__) {
                    return `/assets/${name}`;
                }

                return `${s3PublicUrl}${active ? '' : `/${slug}`}/assets/${name}?v=${faviconVersion}`;
            }

            const getImageUrl = src => {
                if (__DEV__) {
                    return src;
                }

                return `${s3PublicUrl}${src}`;
            }

            return (
                <>
                    <Helmet
                        meta={[
                            metaOgTitle ? { name: 'og:title', content: pageSeo && pageSeo.title ? pageSeo.title : metaOgTitle } : {},
                            metaTwitterTitle ? { name: 'twitter:title', content: pageSeo && pageSeo.title ? pageSeo.title : metaTwitterTitle } : {},
                            metaDescription ? { name: 'description', content: pageSeo && pageSeo.description ? pageSeo.description : metaDescription } : {},
                            metaOgDescription ? { name: 'og:description', content: pageSeo && pageSeo.description ? pageSeo.description : metaOgDescription } : {},
                            metaTwitterDescription ? { name: 'twitter:description', content: pageSeo && pageSeo.description ? pageSeo.description : metaTwitterDescription } : {},
                            metaOgImageUrl ? { name: 'og:image', content: getImageUrl(metaOgImageUrl) } : {},
                            metaTwitterImageUrl ? { name: 'twitter:image', content: getImageUrl(metaTwitterImageUrl) } : {},
                            metaThemeColor ? { name: 'theme-color', content: metaThemeColor } : {},
                            metaThemeColor ? { name: 'msapplication-TileColor', content: metaThemeColor } : {},
                            { name: 'og:type', content: 'website' },
                            { name: 'og:url', content: `${s3PublicUrl}${path}` },

                            { name: 'apple-mobile-web-app-capable', content: 'yes' },
                            { name: 'apple-mobile-web-app-status-bar-style', content: 'black-translucent' },
                            { name: 'apple-mobile-web-app-title', content: 'yes' },
                            { name: 'application-name', content: title },
                            { name: 'mobile-web-app-capable', content: 'yes' },
                            { name: 'msapplication-TileImage', content: getAssetUrl('mstile-144x144.png') },
                            { name: 'msapplication-config', content: getAssetUrl('browserconfig.xml') },

                            !active ? { name: 'robots', content: 'noindex' } : {},
                            active && updatedAt ? { 'http-equiv': 'last-modified', content: (new Date(updatedAt)).toGMTString() } : {},
                        ]}

                        link={[
                            { rel: 'canonical', href: `${s3PublicUrl}${path}` },
                            { rel: 'apple-touch-icon', sizes: '57x57', href: getAssetUrl('apple-touch-icon-57x57.png') },
                            { rel: 'apple-touch-icon', sizes: '60x60', href: getAssetUrl('apple-touch-icon-60x60.png') },
                            { rel: 'apple-touch-icon', sizes: '72x72', href: getAssetUrl('apple-touch-icon-72x72.png') },
                            { rel: 'apple-touch-icon', sizes: '114x114', href: getAssetUrl('apple-touch-icon-114x114.png') },
                            { rel: 'apple-touch-icon', sizes: '120x120', href: getAssetUrl('apple-touch-icon-120x120.png') },
                            { rel: 'apple-touch-icon', sizes: '144x144', href: getAssetUrl('apple-touch-icon-144x144.png') },
                            { rel: 'apple-touch-icon', sizes: '152x152', href: getAssetUrl('apple-touch-icon-152x152.png') },
                            { rel: 'apple-touch-icon', sizes: '167x167', href: getAssetUrl('apple-touch-icon-167x167.png') },
                            { rel: 'apple-touch-icon', sizes: '180x180', href: getAssetUrl('apple-touch-icon-180x180.png') },
                            { rel: 'apple-touch-icon', sizes: '1024x1024', href: getAssetUrl('apple-touch-icon-1024x1024.png') },
                            { rel: 'apple-touch-startup-image', media: '(device-width: 320px) and (device-height: 480px) and (-webkit-device-pixel-ratio: 1)', href: getAssetUrl('apple-touch-startup-image-320x460.png') },
                            { rel: 'apple-touch-startup-image', media: '(device-width: 320px) and (device-height: 480px) and (-webkit-device-pixel-ratio: 2)', href: getAssetUrl('apple-touch-startup-image-640x920.png') },
                            { rel: 'apple-touch-startup-image', media: '(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2)', href: getAssetUrl('apple-touch-startup-image-640x1096.png') },
                            { rel: 'apple-touch-startup-image', media: '(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)', href: getAssetUrl('apple-touch-startup-image-750x1294.png') },
                            { rel: 'apple-touch-startup-image', media: '(device-width: 414px) and (device-height: 736px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3)', href: getAssetUrl('apple-touch-startup-image-1182x2208.png') },
                            { rel: 'apple-touch-startup-image', media: '(device-width: 414px) and (device-height: 736px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3)', href: getAssetUrl('apple-touch-startup-image-1242x2148.png') },
                            { rel: 'apple-touch-startup-image', media: '(device-width: 768px) and (device-height: 1024px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 1)', href: getAssetUrl('apple-touch-startup-image-748x1024.png') },
                            { rel: 'apple-touch-startup-image', media: '(device-width: 768px) and (device-height: 1024px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2)', href: getAssetUrl('apple-touch-startup-image-1496x2048.png') },
                            { rel: 'apple-touch-startup-image', media: '(device-width: 768px) and (device-height: 1024px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 1)', href: getAssetUrl('apple-touch-startup-image-768x1004.png') },
                            { rel: 'apple-touch-startup-image', media: '(device-width: 768px) and (device-height: 1024px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3)', href: getAssetUrl('apple-touch-startup-image-1536x2008.png') },
                            { rel: 'icon', type: 'image/png', sizes: "16x16", href: getAssetUrl('favicon-16x16.png') },
                            { rel: 'icon', type: 'image/png', sizes: "32x32", href: getAssetUrl('favicon-32x32.png') },
                            { rel: 'icon', type: 'image/png', sizes: "228x228", href: getAssetUrl('favicon-228x228.png') },
                            { rel: 'manifest', href: getAssetUrl('manifest.json') },
                            { rel: 'shortcut icon', href: getAssetUrl('favicon.ico') },
                            { rel: 'yandex-tableau-widget', href: getAssetUrl('yandex-browser-manifest.json') },
                        ]}
                    >
                        <html lang="en" />
                        <title>{pageSeo && pageSeo.title ? pageSeo.title : title}</title>
                    </Helmet>
                    {children}
                </>
            );
        }}
    />
);

export default Layout;

