import { generatePaddingClasses } from '../../utils';

import CssClassNames from '../../scss/CssClassNames';
import styles from './styles.module.scss';
const { className } = new CssClassNames(styles);

export const sharedContainerClasses = (pt, pb) => {
    let classes = [];
    classes = generatePaddingClasses(pt, 't', classes);
    classes = generatePaddingClasses(pb, 'b', classes);
    return  className(`${classes.join(' ')}`);
}