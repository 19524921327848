import React from 'react';

import isSharedComponent from '../../hoc/isSharedComponent';

import { Row, Col } from '../Bootstrap/Grid';
import { generatePaddingClasses } from '../../utils';

import CssClassNames from '../../scss/CssClassNames';
import styles from './styles.module.scss';
const { className } = new CssClassNames(styles);

const SCNaeTexts = ({pt, pb, naeTextItems = [] }) => {
    let classes = [];
    classes = generatePaddingClasses(pt, 't', classes);
    classes = generatePaddingClasses(pb, 'b', classes);

    return (
        <div {...className(`${classes.join(' ')}`)}>
            <Row {...className(`position-relative`)}>
                <Col col={{xs: 12, xl: 10}} {...className(`h-100 position-absolute box-col`)}>
                    <div {...className(`${styles.box} h-100`)} />
                </Col>
                <Col {...className('box-content')} offset={{ lg: 2 }} col={{ lg: 6 }} pt={{xs: 12, lg: 8}} pb={{xs: 6, xxl: 2}}>
                    {naeTextItems.map((item, i) => (
                        <div key={i} {...className(`box-content-item mb-6 mb-lg-8`)}>
                            {item.title && <h2 {...className(`${styles.title} mb-${item.subtitle ? 0 : 3}`)}>
                                {item.title}
                            </h2>}
                            {item.subtitle ? <p {...className('mb-3')}><strong>{item.subtitle}</strong></p> : null}
                            <p>
                                {item.text}
                            </p>
                        </div>
                    ))}
                </Col>

            </Row>
        </div>
    );
}

export default isSharedComponent(SCNaeTexts, 'SCNaeTexts');
