import React, { memo, useRef, useState, useEffect } from 'react';
import isSharedComponent from '../../hoc/isSharedComponent';

import Arrow from './Arrow';
import ImageSlider from './ImageSlider';
import { Row, Col } from '../Bootstrap/Grid';
import { Slider } from '../Bootstrap/Slider';
import { generatePaddingClasses } from '../../utils';

import CssClassNames from '../../scss/CssClassNames';
import styles from './styles.module.scss';
const { className } = new CssClassNames(styles);


const SCNaeSlider = ({ children, pt, pb, position, naeSliderItems, relation }) => {

    const sliderSettings = {
        controls: false,
        nav: false,
        mouseDrag: true,
        loop: false,
        rewind: true
    }


    const [indexImage, setIndexImage] = useState(1);
    const sliderRef = useRef();

    // TODO: CHECK ON CLIENT
    // useEffect(() => {
    //     if (sliderRef.current) {           
    //         console.log(sliderRef.current);
    //     }
    // }, []);

    const onClickArrow = (dir) => () => {
        if (sliderRef.current) {
            sliderRef.current.slider.goTo(dir);
        }
    }

    const onIndexChanged = (e) => {
        setIndexImage(e.index + 1)
    }

    const [, x, y] = (/([1-3])_([1-3])/gm).exec(relation);
    const [, posY, posX] = (/([^_]+)_([^_]+)/gm).exec(position);

    let classes = [`container-fluid`];

    classes = generatePaddingClasses(pt, 't', classes, 'm');
    classes = generatePaddingClasses(pb, 'b', classes, 'm');

    return (
        <div {...className(classes.join(' '))}>
            <Row {...className(`position-relative`)}>
                <Col col={12} {...className(`${styles.box} position-absolute w-100 h-100`)} >
                    <div {...className(`${styles['box-bg']} w-100 h-100`)} />
                </Col>
                <Col {...className(`${styles.content} ${posY === 'top' ? `padBottom-${y}` : `padTop-${y}`}`)} offset={posX === 'right' ? x : 0} col={12 - parseInt(x)}>
                    {naeSliderItems.length > 0 &&
                        <>
                            {naeSliderItems.length > 1 ?
                                <Slider settings={sliderSettings} parentRef={sliderRef} onIndexChanged={onIndexChanged}>
                                    {children}
                                </Slider>
                                :
                                <ImageSlider {...naeSliderItems[0]} />
                            }
                        </>
                    }
                </Col>
            </Row>
            {naeSliderItems.length > 1 &&
                <Row>
                    <Col col={{ xs: 12, lg: 3, xl: 2 }} offset={{ xs: 0, lg: 9, xl: 10 }}>
                        <div {...className('mt-2 d-flex justify-content-between align-items-center')}>
                            <Arrow direction='prev' onClickArrow={onClickArrow} />
                            <div {...className('text-muted')}>
                                <span {...className('text-dark')}>{indexImage}</span> / {naeSliderItems.length}
                            </div>
                            <Arrow onClickArrow={onClickArrow} />
                        </div>
                    </Col>
                </Row>
            }
        </div>
    );
};

export default memo(isSharedComponent(SCNaeSlider, 'SCNaeSlider'));