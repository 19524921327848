import React, { Fragment, memo, useState } from 'react';
import isSharedComponent from '../../hoc/isSharedComponent';

import DownloadButton from '../DownloadButton';
import Heading from '../Heading';

import CssClassNames from '../../scss/CssClassNames';
import styles from './styles.module.scss';
import { sharedContainerClasses } from './classGenerator';
const { className } = new CssClassNames(styles);

const SCHeadingDownload = memo(({ 
    pt, 
    pb,
    hasBorder,
    labelButton,
    gatsbyFile = null,
    checkboxs,
    ...props }) => {

    const classesDiv = sharedContainerClasses(pt, pb);
    
    const [selected, setSelected] = useState([]);

    const handleClickCheckbox = (url) => (e) => {
        console.log(e, url);
        e.preventDefault();
        setSelected(state => {
            if (state.includes(url)) {
                const newState = state.filter(item => item !== url);
                return newState;
            } else {
                const newState = [...state, url]
                return newState;
            }
        })
    }

    const handleSelectedClick = (e) => {
        e.preventDefault();
        selected.forEach(url => {
            const a = document.createElement('a');
            a.href = url;
            a.download = true;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        });
    }

    return (
        <div  
            {...classesDiv}
            {...(props.isHashable ? { id: props.hash } : {})}
        >
            <Container hasBorder={hasBorder}>
                <Heading {...props} />
                <div {...className('d-flex align-items-center')}>
                    {checkboxs.length > 0 && (
                        <DownloadButton {...className('mr-2')} onClick={handleSelectedClick} disabled={selected.length === 0}>
                            Selected
                        </DownloadButton>
                    )}
                    {labelButton && (
                        <DownloadButton gatsbyFile={gatsbyFile} disabled={selected.length > 0}>
                            {labelButton}
                        </DownloadButton>
                    )}
                </div>
            </Container>
            {checkboxs.length > 0 && (
                <div {...className('d-flex flex-wrap mt-5')}>
                    {checkboxs.map((item,i) => (
                        <Fragment key={i}>
                            {item.gatsbyFile && (
                                <a href="#" onClick={handleClickCheckbox(item.gatsbyFile.publicURL)} {...className(`checkbox mt-2 mr-6 ${selected.includes(item.gatsbyFile.publicURL) ? 'active':''}`)}>
                                    {item.label || 'Label'}
                                </a>
                            )}
                        </Fragment>
                    ))}
                </div>
            )}
        </div>
    )
});

const Container = memo(({children, hasBorder, ...props}) => (  
    <>  
        {hasBorder ? (
            <div {...className('d-flex justify-content-between border')} {...props}>
                {children}
            </div>
        ): children}
    </>
))

export default isSharedComponent(SCHeadingDownload, 'SCHeadingDownload');